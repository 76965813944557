import React from "react";
import { faGreaterThan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Adjuct = () => {
  return (
    <div>
      <div style={{ marginTop: "25px" }}>
        <FontAwesomeIcon icon={faGreaterThan} size="small" color="black" />
        {"    "}
        Taught 15 classes amounting to 300 student in the span of 18 months
      </div>

      <div style={{ marginTop: "25px" }}>
        <FontAwesomeIcon icon={faGreaterThan} size="small" color="black" />
        {"    "}
        Instructed 3 courses: Introduction to Computer Science, OOP: C++, OOP:
        Java
      </div>
      <div style={{ marginTop: "25px" }}>
        <FontAwesomeIcon icon={faGreaterThan} size="small" color="black" />
        {"    "}
        Hosted 240 office hours to help reenforce computer science theories and
        concepts
      </div>
      <div style={{ marginTop: "25px" }}>
        <FontAwesomeIcon icon={faGreaterThan} size="small" color="black" />
        {"    "}
        Code review student's projects and assignments on a weekly basis,
        providing constructive feedback
      </div>
      <div style={{ marginTop: "25px" }}>
        <FontAwesomeIcon icon={faGreaterThan} size="small" color="black" />
        {"    "}
        Impletemented my proposal to hold extra classes during finals week to
        provide extra guide to students
      </div>
    </div>
  );
};

export default Adjuct;
