import React from "react";
import { faGreaterThan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Professional = () => {
  return (
    <div>
      <div style={{ marginTop: "25px" }}>
        <FontAwesomeIcon icon={faGreaterThan} size="small" color="black" />
        {"    "}Experience in Front-end development for fast pace start-up
      </div>
      <div style={{ marginTop: "25px" }}>
        <FontAwesomeIcon icon={faGreaterThan} size="small" color="black" />
        {"    "}Active contributor of innovative ideas in a team enviroment
      </div>
      <div style={{ marginTop: "25px" }}>
        <FontAwesomeIcon icon={faGreaterThan} size="small" color="black" />
        {"    "}Problem solver with an unique analytical mindset
      </div>
    </div>
  );
};

export default Professional;
