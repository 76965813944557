import React from "react";
import Lottie from "react-lottie";
const Lotties = (Animation) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Animation.Animation,
    rendererSettings: {},
  };
  return <Lottie options={defaultOptions} />;
};
export default Lotties;
