import React, { useState } from "react";
import "./style.css";
import AppButton from "../../Components/App";
import Animation from "../../Components/Animation";
import taptab from "../../json/taptab.json";
import education from "../../json/education.json";
import teacher from "../../json/teacher.json";
import coding from "../../json/coding.json";
import TapTab from "../../Components/Experience/Taptab";
import Adjuct from "../../Components/Experience/Adjuct";
import Education from "../../Components/Experience/Education";
import Skills from "../../Components/Experience/Skills";
const Resume = () => {
  const [content, setContent] = useState("TapTab");

  const handleClick = (event) => {
    setContent(event.target.id);
  };

  const handleTextContext = () => {
    if (content === "TapTab") {
      return <TapTab />;
    } else if (content === "Adjuct") {
      return <Adjuct />;
    } else if (content === "Education") {
      return <Education />;
    } else if (content === "Skills") {
      return <Skills />;
    }
  };

  const websiteDisplay = () => {
    const object = [
      {
        component: (
          <AppButton
            appTitle={<Animation Animation={taptab} />}
            title="TapTab"
            color="lightblue"
          />
        ),
        title: "TapTab",
      },
      {
        component: (
          <AppButton
            appTitle={<Animation Animation={teacher} />}
            title="Adjuct Prof."
            color="lightgreen"
          />
        ),
        title: "Adjuct",
      },
      {
        component: (
          <AppButton
            appTitle={<Animation Animation={education} />}
            title="Education"
            color="pink"
          />
        ),
        title: "Education",
      },
      {
        component: (
          <AppButton
            appTitle={<Animation Animation={coding} />}
            title="Skills"
            color="rgb(248,197,149)"
          />
        ),
        title: "Skills",
      },
    ];
    return object.map((app) => {
      return (
        <div
          style={{ cursor: "pointer" }}
          id={app.title}
          onClick={(event) => {
            handleClick(event);
          }}
        >
          {app.component}
        </div>
      );
    });
  };
  return (
    <div class="ResumeContainer">
      <div class="WhoAmIContainer" style={{ width: "23%" }}>
        <div class="WhoAmITitle">Experience</div>
        <div class="AppContainerAboutMe">{websiteDisplay()}</div>
      </div>
      <div class="AboutMeContainer" style={{ width: "65%" }}>
        <div class="websiteUI">
          <span class="buttonUX" style={{ cursor: "pointer" }}>
            {" "}
            🔴{" "}
          </span>
          <span class="buttonUX"> 🟢 </span>
          <span class="buttonUX"> 🟡 </span>
        </div>
        <div class="AboutMeContent">{handleTextContext()}</div>
      </div>
    </div>
  );
};

export default Resume;
