import React from "react";
import { faGreaterThan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Person = () => {
  return (
    <div>
      <div style={{ marginTop: "25px" }}>
        <FontAwesomeIcon icon={faGreaterThan} size="small" color="black" />
        {"    "}Affinity for animals even though I am allergic to most animals
      </div>
      <div style={{ marginTop: "25px" }}>
        <FontAwesomeIcon icon={faGreaterThan} size="small" color="black" />
        {"    "}
        Aspiring chef of varying cuisines that can be baked in the oven or
        air-fryer
      </div>
      <div style={{ marginTop: "25px" }}>
        <FontAwesomeIcon icon={faGreaterThan} size="small" color="black" />
        {"    "}One Piece, ask me anything
      </div>
    </div>
  );
};

export default Person;
