import React from "react";
import "./style.scss";
import Intro from "../../Screen/Intro";
import About from "../../Screen/About";
import Resume from "../../Screen/Resume";
const Icon = () => {
  return (
    <>
      <div class="content">BL</div>

      <section class="one">
        <div class="wrapper">
          <Intro />
        </div>
      </section>

      <section class="two">
        <div class="wrapper">
          <About />
        </div>
      </section>

      <section class="three">
        <div class="wrapper">
          <Resume />
        </div>
      </section>

      <section class="four">
        <div class="wrapper"></div>
      </section>
    </>
  );
};

export default Icon;
