import React from "react";
import {
  faReact,
  faNode,
  faCss3,
  faHtml5,
  faSass,
  faBootstrap,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style.css";
const Skills = () => {
  return (
    <div class="skillContainer">
      <div class="skillIcons">
        <FontAwesomeIcon icon={faReact} size="8x" color="lightblue" />
        <div class="skillIconsTitle">React</div>
      </div>
      <div class="skillIcons">
        <FontAwesomeIcon icon={faHtml5} size="8x" color="#DE4B24" />
        <div class="skillIconsTitle">Html5</div>
      </div>
      <div class="skillIcons">
        <FontAwesomeIcon icon={faCss3} size="8x" color="#4B6EF5" />
        <div class="skillIconsTitle">CSS</div>
      </div>
      <div class="skillIcons">
        <FontAwesomeIcon icon={faSass} size="8x" color="#C76495" />
        <div class="skillIconsTitle">Sass</div>
      </div>
      <div class="skillIcons">
        <FontAwesomeIcon icon={faNode} size="8x" color="green" />
        <div class="skillIconsTitle">Node</div>
      </div>
      <div class="skillIcons">
        <FontAwesomeIcon icon={faBootstrap} size="8x" color="#543B79" />
        <div class="skillIconsTitle">Bootstrap</div>
      </div>
    </div>
  );
};

export default Skills;
