import React, { useState } from "react";
import "./style.css";
import Animation from "../../Components/Animation";
import Working from "../../json/Working.json";
import play from "../../json/play.json";
import professional from "../../json/professional.json";
import AppButton from "../../Components/App";
import Professional from "../../Components/AboutMeContent/Professional";
import Developer from "../../Components/AboutMeContent/Developer";
import Person from "../../Components/AboutMeContent/Person";

const About = () => {
  const [toggle, setToggle] = useState(true);
  const [content, setContent] = useState("Person");

  const handleClick = (event) => {
    setContent(event.target.id);
    setToggle(true);
  };

  const handleTextContext = () => {
    if (content === "Professional") {
      return <Professional />;
    } else if (content === "Developer") {
      return <Developer />;
    } else if (content === "Person") {
      return <Person />;
    }
  };
  const handleAnimationContext = () => {
    if (content === "Professional") {
      return <Animation Animation={professional} />;
    } else if (content === "Developer") {
      return <Animation Animation={Working} />;
    } else if (content === "Person") {
      return <Animation Animation={play} />;
    }
  };

  const websiteDisplay = () => {
    const object = [
      {
        component: (
          <AppButton
            appTitle={<Animation Animation={play} />}
            title="Person"
            color="salmon"
          />
        ),
        title: "Person",
      },
      {
        component: (
          <AppButton
            appTitle={<Animation Animation={professional} />}
            title="Professional"
            color="purple"
          />
        ),
        title: "Professional",
      },
      {
        component: (
          <AppButton
            appTitle={<Animation Animation={Working} />}
            title="Developer"
            color="blue"
          />
        ),
        title: "Developer",
      },
    ];
    return object.map((app) => {
      return (
        <div
          style={{ cursor: "pointer" }}
          id={app.title}
          onClick={(event) => {
            handleClick(event);
          }}
        >
          {app.component}
        </div>
      );
    });
  };

  return (
    <div class="rootAbout">
      {toggle ? (
        <div class="AboutMeContainer">
          <div class="websiteUI">
            <span
              class="buttonUX"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setToggle(!toggle);
              }}
            >
              {" "}
              🔴{" "}
            </span>
            <span class="buttonUX"> 🟢 </span>
            <span class="buttonUX"> 🟡 </span>
          </div>
          <div class="AboutMeContent">{handleTextContext()}</div>
        </div>
      ) : null}
      <div class="WhoAmIContainer">
        <div class="WhoAmITitle">Who Am I?</div>
        <div class="AppContainerAboutMe">{websiteDisplay()}</div>
      </div>
      <div class="animationBackground">{handleAnimationContext()}</div>
    </div>
  );
};

export default About;
