import React, { useState } from "react";
import Lotties from "./Lotties";
import { Waypoint } from "react-waypoint";

const Animation = ({ Animation }) => {
  let [renderLottie, setRenderLottie] = useState(false);

  return (
    <div style={{ pointerEvents: "none" }}>
      <Waypoint onEnter={() => setRenderLottie(true)} />
      {renderLottie && <Lotties Animation={Animation} />}
    </div>
  );
};

export default Animation;
