import React from "react";
import "./style.css";
import Image from "../../json/Image.jpg";

const Card = () => {
  const date = Date().split("").splice(0, 21);

  return (
    <div class="CardRoot">
      <div class="InfoContainer">
        <div class="phoneUX">
          <div class="Phonestuff">
            <div>T-MOBILE 5G</div>
            <div>{date}</div>
            <div>100% BATTERY</div>
          </div>
          <div class="URL">www.Bobbyliu1.com</div>
        </div>
        <div class="PhoneTitle">
          Developer Looking for a Job! Maybe you could be it!
        </div>
        <div>
          <img src={Image} class="Image" alt="pikachu" />
        </div>
        <div class="InfoCard">
          <div>
            <div class="name">Bobby Liu</div>
          </div>
          <div class="summaryContainer">
            <div class="subSummaryContainer">
              <div class="label">Occupation</div>
              <div class="response">Front-end Developer</div>
              <div class="label">Worked With</div>
              <div class="response">TapTab</div>
            </div>
            <div class="subSummaryContainer">
              <div class="label">Experience</div>
              <div class="response">Entry</div>
              <div class="label">Skills</div>
              <div class="response">React, Express, ProgresSQL</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
