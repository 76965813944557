import React from "react";
import "./style.css";

const AppButton = ({ appTitle, title, color }) => {
  return (
    <div className="appContainer" id={title} style={{ pointerEvents: "none" }}>
      <div className="AppButton" style={{ backgroundColor: `${color}` }}>
        <div className="animationApp">{appTitle}</div>
      </div>
      <div className="appTitle">{title}</div>
    </div>
  );
};

export default AppButton;
