import React from "react";
import { faGreaterThan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Taptab = () => {
  return (
    <div>
      <div style={{ marginTop: "25px" }}>
        <FontAwesomeIcon icon={faGreaterThan} size="small" color="black" />
        {"    "}
        Front-end Engineer at TapTab, focused on building out a UI for the Admin
        Web Browser portal
      </div>
      <div style={{ marginTop: "25px" }}>
        <FontAwesomeIcon icon={faGreaterThan} size="small" color="black" />
        {"    "}
        Provide constructive feedback on UI /UX design feasibility from a
        technical implementation perspective
      </div>
      <div style={{ marginTop: "25px" }}>
        <FontAwesomeIcon icon={faGreaterThan} size="small" color="black" />
        {"    "}
        Collaborate with Back-end Team to architect servers endpoints needed to
        push out the end product
      </div>
      <div style={{ marginTop: "25px" }}>
        <FontAwesomeIcon icon={faGreaterThan} size="small" color="black" />
        {"    "}
        Partner with the CEO of the company and provide technical consultation
        on my area of expertise
      </div>
      <div style={{ marginTop: "25px" }}>
        <FontAwesomeIcon icon={faGreaterThan} size="small" color="black" />
        {"    "}
        Spend 6 hours a week mentoring a group of interns on the ins-and-outs of
        working on a development team
      </div>
    </div>
  );
};

export default Taptab;
