import React from "react";
import { faGreaterThan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Education = () => {
  return (
    <div>
      <div style={{ marginTop: "25px" }}>
        <FontAwesomeIcon icon={faGreaterThan} size="small" color="black" />
        {"    "}
        Completed the Software Engineering Immersive at General Assembly
      </div>
      <div style={{ marginTop: "25px" }}>
        <FontAwesomeIcon icon={faGreaterThan} size="small" color="black" />
        {"    "}
        Graduated with a bacholar of Computer Science at The City University of
        New York: Queens College
      </div>
    </div>
  );
};

export default Education;
